import { useEffect, useState } from "react";
import { Slider } from "rsuite";
import { LegendLevels } from "../utils/Constants";

function Legend(props) {
   const [opacity, setOpacity] = useState(6);

   const changeOpacity = (opacity) => {
      setOpacity(opacity);
      props.changeOpacity(opacity/10);
   }

   useEffect(() => {
      setOpacity(6)
   }, [])

   return(
      <>
      <div className="legend-container">
         <div className='map-legend'>
            <div className='legend-title'>Plastic items</div>
            <div className="levels">
               {LegendLevels.map((level,i) => (
               <div key={i} className="level">
                  <span className='level-key' style={{backgroundColor: level.color}}></span>
                  <span>{level.range}</span>
               </div>
               ))}
            </div>
         </div>
         <div className="opacity-slider">
            <p>Transparency</p>
            <Slider min={0} max={10} value={opacity}
               className="input" handleStyle={{ width: 25, height: 15 }}
               tooltip={false} onChange={(val) => changeOpacity(val)}
            />
         </div>
      </div>
      </>
   )
}

export default Legend;