import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// Stop "npm build" from transpiling mapbox code.
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { useEffect, useRef, useState } from 'react';
import Morocco from '../utils/morocco.geojson';
import MoroccoStar from '../assets/img/morocco-star.png';
import DakhlaSites from '../utils/dakhla-sites.geojson';
import PlasticData from '../utils/plastic-data.geojson';
import { QuantityToColorCases } from '../utils/Constants'
import Legend from './Legend';
import SiteReport from './SiteReport';
import TimeSlider from './TimeSlider';
import ResizeObserver from 'resize-observer-polyfill';
import PulsingDot from './PulsingDot';
import { TbHandClick } from 'react-icons/tb';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1Ijoic2FsbWFuLWVmIiwiYSI6ImNsN2RrMzEyZDFteDAzcW84NmR4OTI1YzgifQ.M6Gh4XttunB5GLpC1Izbdg';

const hoverPopup = new mapboxgl.Popup({
  closeButton: false,
  className: 'map-hover-popup',
  anchor: 'left'
});
const detailsPopup = new mapboxgl.Popup({
  closeButton: false,
  className: 'map-hover-popup',
  anchor: 'bottom'
});

function Map() {
   const mapContainer = useRef(null);
   const map = useRef(null);
   const [lngLat, setLngLat] = useState({lng:-15.910263061523438,lat:23.782831486445172});
   const [site, setSite] = useState({});
   const [siteQuantities, setSiteQuantities] = useState([]);
   const [hoveredSite, setHoveredSite] = useState(null);
   const [focusedFeature, setFocusedFeature] = useState(null);
   const [legendShown, showLegend] = useState(false);
   const [detailsShown, showDetails] = useState(false);
   const [timeSliderShown, showTimeSlider] = useState(false);

   useEffect(() => {
      if (!map.current) { // initialize map only once
         map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-v9',
            bounds: [ [-19.6875,38.92522904714054],[-0.52734375,15.749962572748768] ]
         }).on('click', (e) => {
            setLngLat(e.lngLat);
         });
         // map controls
         map.current.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'bottom-right');
         // map layers
         map.current.on('load', () => {
            map.current.addImage('pulsing-dot', PulsingDot(map.current), { pixelRatio: 2 });
            // Map layers
            map.current.addSource('morocco', {
               'type': 'geojson',
               'data': Morocco
            });
            map.current.addSource('morocco-star', {
               'type': 'image',
               'url': MoroccoStar,
               'coordinates': [
                  [-8.096,33.008],
                  [-5.152,33.008],
                  [-5.152,30.770],
                  [-8.096,30.770],
               ]
            });
            map.current.addLayer({
               'id': 'morocco-layer',
               'type': 'fill',
               'source': 'morocco',
               'paint': {
                  'fill-color': '#bc262b'
               }
            });
            map.current.addLayer({
               'id': 'morocco-star-layer',
               'type': 'raster',
               'source': 'morocco-star'
            });
            map.current.addLayer({
               'id': 'morocco-borders',
               'type': 'line',
               'source': 'morocco',
               'paint': {
                  'line-color': [ 'case', [ 'boolean', ['feature-state', 'hover'], false], '#f0af19', '#000' ],
                  'line-width': [ 'case', [ 'boolean', ['feature-state', 'hover'], false], 3, 1 ]
               }
            });
            map.current.addSource('dakhla-sites', {
               'type': 'geojson',
               'data': DakhlaSites
            });
            map.current.addLayer({
               'id': 'dakhla-sites-layer',
               'type': 'symbol',
               'source': 'dakhla-sites',
               'layout': {
                  'visibility': 'none',
                  'icon-image': 'pulsing-dot',
                  'icon-allow-overlap': true
               }
            });
            map.current.addSource('plastic-data', {
               'type': 'geojson',
               'data': PlasticData
            });
            map.current.addLayer({
               'id': 'plastic-data-layer',
               'type': 'fill',
               'source': 'plastic-data',
               'layout': {
                  'visibility': 'none'
               },
               'paint': {
                  // Conditional colors on each feature based on 'quantity' property
                  'fill-color': QuantityToColorCases,
                  'fill-opacity': [ 'case', [ 'boolean', ['feature-state', 'focus'], false], 1, .6 ],
                  'fill-outline-color': '#1F2023'
               }
            });
         })
      }

      // Morocco layer events
      map.current.on('mousemove', 'morocco-layer', (e) => {
         map.current.getCanvas().style.cursor = 'pointer';
         map.current.setFeatureState({ source: 'morocco', id: e.features[0].id }, { hover: true });
         setHoveredSite(e.features[0].id);
      });
      map.current.on('mouseleave', 'morocco-layer', (e) => {
         map.current.getCanvas().style.cursor = '';
         map.current.setFeatureState({ source: 'morocco', id: hoveredSite}, { hover: false });
         setHoveredSite(null);
         hoverPopup.remove()
      });
      map.current.on('click', 'morocco-layer', (e) => {
         map.current.fitBounds([ [-16.121063232421875,23.60740778883866], [-15.669250488281248,23.961783669896683] ]);
         setTimeout(() => {
            map.current.setLayoutProperty('morocco-layer', 'visibility', 'none');
            map.current.setLayoutProperty('morocco-borders', 'visibility', 'none');
            map.current.setLayoutProperty('morocco-star-layer', 'visibility', 'none');
            map.current.once('moveend', () => {
               map.current.setLayoutProperty('dakhla-sites-layer', 'visibility', 'visible');
            })
         }, 1000);
      })

      // Dakhla layer events
      map.current.on('mousemove', 'dakhla-sites-layer', (e) => {
         const features = map.current.queryRenderedFeatures(e.point);
         map.current.getCanvas().style.cursor = 'pointer';
         map.current.setFeatureState({ source: 'dakhla-sites', id: features[0].id }, { hover: true });
         setHoveredSite(features[0].id);
         hoverPopup
            .addTo(map.current)
            .setLngLat(e.lngLat)
            .setHTML(`<p>${features[0].properties.name}</p>`)
      });
      map.current.on('mouseleave', 'dakhla-sites-layer', (e) => {
         map.current.getCanvas().style.cursor = '';
         map.current.setFeatureState({ source: 'dakhla-sites', id: hoveredSite}, { hover: false });
         setHoveredSite(null);
         hoverPopup.remove()
      });
      map.current.on('click', 'dakhla-sites-layer', (e) => {
         const features = map.current.queryRenderedFeatures(e.point), 
               bounds = JSON.parse(features[0].properties.bounds);
         map.current.fitBounds(bounds);

         // Update chosen site & filter layer data based on it
         setSite(features[0]);
         
         map.current.setLayoutProperty('dakhla-sites-layer', 'visibility', 'none');
         setTimeout(() => {
            map.current.setLayoutProperty('plastic-data-layer', 'visibility', 'visible');
            map.current.setFilter('plastic-data-layer', ['==', 'site', features[0].id])
            map.current.once('moveend', () => {
               showLegend(true);
               showDetails(true);
               showTimeSlider(true);
               // List all site quantities uniquely by date to be used in the time slider & report chart
               let allFeatures = map.current.querySourceFeatures('plastic-data', {sourceLayer: 'plastic-data-layer', filter: ['==', 'site', features[0].id]});
               // Because querySourceFeatures returns duplicates we need to filter & calculate features at the same time
               let quantitiesByDate = [];
               let featuresIds = []; // Keep tracking features ids to check duplicates
               allFeatures.forEach((feat) => {
                  const isDateExisted = quantitiesByDate.filter((qD) => qD.date === feat.properties.date);
                  if(!featuresIds.includes(feat.id)) {
                     featuresIds.push(feat.id)
                     if(!isDateExisted.length) {
                        // When feature date not in quantities list => add feature quantity as date sum of quantities
                        quantitiesByDate.push({ date: feat.properties.date, quantity: feat.properties.quantity })
                     } else {
                        // When feature date alreay added => just add feature quantity to current date sum of quantities
                        quantitiesByDate = quantitiesByDate.map(qD =>
                           (qD.date === feat.properties.date)
                           ? { date: qD.date, quantity: qD.quantity + feat.properties.quantity }
                           : qD
                        )
                     }
                  }
               })
               // Sort the dates oldest to newest
               quantitiesByDate.sort((qDA, qDB) => Date.parse(qDA.date) - Date.parse(new Date(qDB.date)))
               setSiteQuantities(quantitiesByDate)
               // First load: Only show data of the newest date
               filterDataByDate(quantitiesByDate[quantitiesByDate.length-1]?.date);
            })
         }, 1000);
      })

      // Plastic data layer events
      map.current.on('mousemove', 'plastic-data-layer', (e) => {
         map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('click', 'plastic-data-layer', (e) => {
         const features = map.current.queryRenderedFeatures(e.point);
         map.current.getCanvas().style.cursor = 'pointer';
         setFocusedFeature(features[0].id);
         map.current.setFeatureState({ source: 'plastic-data', id: features[0].id }, { focus: true });
         detailsPopup
            .addTo(map.current)
            .setLngLat(e.lngLat)
            .setHTML(`<div class="properties">
                        <p class="property"><span class="label">Quantity:</span> ${features[0].properties?.quantity} items</p>\
                     </div>`)
      });
      map.current.on('mouseleave', 'plastic-data-layer', (e) => {
         map.current.getCanvas().style.cursor = '';
      });

   });

   // Map container resize watcher to keep map size expanded
   useEffect(() => {
      const resizeObserver = new ResizeObserver((entries, observer) => {
         map.current.resize();
      });
      resizeObserver.observe(mapContainer.current);
      return () => resizeObserver.disconnect();
   }, [])
   
   const resetSitesView = () => {
      map.current.fitBounds([ [-16.121063232421875,23.60740778883866], [-15.669250488281248,23.961783669896683] ]);
      showLegend(false);
      showDetails(false);
      showTimeSlider(false);
      setSite({});
      setHoveredSite({});
      detailsPopup.remove()
      map.current.setLayoutProperty('plastic-data-layer', 'visibility', 'none');
      map.current.setPaintProperty('plastic-data-layer', 'fill-opacity', [ 'case', [ 'boolean', ['feature-state', 'focus'], false], 1, .5 ]);
      setTimeout(() => {
         map.current.once('moveend', () => {
            map.current.setLayoutProperty('dakhla-sites-layer', 'visibility', 'visible');
         })
      }, 1000);
   }

   detailsPopup.on('close', (e) => {
      map.current?.setFeatureState({ source: 'plastic-data', id: focusedFeature }, { focus: false });
   })

   const filterDataByDate = (date) => {
      if(date) {
         map.current?.setFilter('plastic-data-layer', ['==', 'date', date])
         detailsPopup.remove()
      }
   }
   const changeDataOpacity = (opacity) => {
      map.current?.setPaintProperty('plastic-data-layer', 'fill-opacity', [ 'case', [ 'boolean', ['feature-state', 'focus'], false], 1, opacity ]);
   }

   return (
      <div className="page map-page">
         <div ref={mapContainer} className="map-container" />
         { (detailsShown) ? (
         <SiteReport site={site} resetSitesView={resetSitesView} quantities={siteQuantities} />
         ) : null}
         { (legendShown) ? (
         <Legend changeOpacity={changeDataOpacity} />
         ) : null }
         { (timeSliderShown) ? (
         <TimeSlider quantities={siteQuantities} onChange={(date) => filterDataByDate(date)} />
         ) : null }
         <div className="lnglat"><TbHandClick /> {lngLat?.lat.toFixed(6)}, {lngLat?.lng.toFixed(6)}</div>
      </div>
   );
}

export default Map;