import { NavLink } from 'react-router-dom';
import HomeLandMp4 from '../assets/video/dcem.mp4';
import HomeLandWebm from '../assets/video/dcem.webm';
import IconAi from '../assets/img/icon-ai.png'
import IconDetect from '../assets/img/icon-detect.png'
import IconSatellite from '../assets/img/icon-satellite.png'
import { useEffect, useState } from 'react';

function Home(props) {
   const [landing, setLanding] = useState(true);
   const [videoLoaded, setVideoLoaded] = useState(false);

   useEffect(() => {
      // Tracking landing status in App component (parent) because local landing state is lost on every render
      if(props.landed) {
         setLanding(false);
         return;
      }
   }, [props])

   // Start landing timer after the video player is loaded
   useEffect(() => {
      if(videoLoaded) {
         setTimeout(() => {
            setLanding(false)
            props.land(true);
         }, 13000);
      }
   }, [videoLoaded, props])

   return (
      <div className={`page home-page ${landing ? 'landing':''}`}>
         <video className="land-video" autoPlay loop muted playsInline onLoadedData={() => setVideoLoaded(true)}>
            <source src={HomeLandMp4} type="video/webm" />
            <source src={HomeLandWebm} type="video/mp4" />
         </video>
         <div className="land-section">
            <div className="content">
               <div className="side side-1">
                  <div className="steps">
                     <div className="step">
                        <img src={IconSatellite} alt="" />
                        <span className="text">Satellite Images</span>
                     </div>
                     <div className="step">
                        <img src={IconAi} alt="" />
                        <span className="text">Artificial Intelligence</span>
                     </div>
                     <div className="step">
                        <img src={IconDetect} alt="" />
                        <span className="text">Plastic Detection</span>
                     </div>
                  </div>
               </div>
               <div className="side side-0">
                  <div className="title">Dakhla Coasts Environmental Monitoring</div>
                  <p className="text">Dakhla Coasts Environmental Monitoring DCEM is an integrated project that aims to develop a digital tool for environmental monitoring that combines remote sensing data (satellite images) and artificial intelligence (AI) to detect and quantify <span className="highlight">plastic</span> waste on four target beaches in Dakhla.</p>
                  <div className="btns">
                     <NavLink to="/about">Read More</NavLink>
                     <NavLink to="/map">Explore Map</NavLink>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Home;