import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Sidenav, Nav } from 'rsuite';
import EagleAI from '../assets/img/eagleai.png';

function SideNav() {
  const [expand, setExpand] = useState(false);
  const [activeKey, setActiveKey] = useState("1");

  return (
    <div className="sidenav">
      <Sidenav expanded={expand}>
        <Sidenav.Body>
          <Sidenav.Toggle onToggle={(expanded) => setExpand(expanded)} />
          <Nav activeKey={activeKey} onSelect={setActiveKey}>
            <li className="rs-sidenav-item">
              <NavLink to="/" onClick={(e)=>setExpand(false)}>Home</NavLink>
            </li>
            <li className="rs-sidenav-item">
              <NavLink to="/map" onClick={(e)=>setExpand(false)} reloadDocument>Map</NavLink>
            </li>
            <li className="rs-sidenav-item">
              <NavLink to="/activities" onClick={(e)=>setExpand(false)}>Activities</NavLink>
            </li>
            <li className="rs-sidenav-item">
              <NavLink to="/about" onClick={(e)=>setExpand(false)}>About</NavLink>
            </li>
            <li className="rs-sidenav-item">
              <NavLink to="/contact" onClick={(e)=>setExpand(false)}>Contact</NavLink>
            </li>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
      <a href="https://eagle-ai.net" className="eagleai" target="_blank" rel="noopener noreferrer">
        <img src={EagleAI} alt="eagleai" />
      </a>
    </div>
  )
}

export default SideNav;