export const LegendLevels = [
   { range: '22-50', color: '#d62040' },
   { range: '17-21', color: '#f19a1b' },
   { range: '11-16', color: '#fbda0a' },
   { range: '6-10', color: '#c5dd76' },
   { range: '0-5', color: '#84cf9e' }
];
// export const LegendLevels = [
   // { range: '22-50', color: '#5e50a2' },
   // { range: '17-21', color: '#5bb6a9' },
   // { range: '11-16', color: '#e7f598' },
   // { range: '6-10', color: '#f99253' },
   // { range: '0-5', color: '#d53e4f' }
// ];

// To be used in MapBox conditional feature coloring based on 'quantity' property
export const QuantityToColorCases = [
   'case',
   ['<', ['get', 'quantity'], 6], LegendLevels[4].color,
   ['all', ['>=', ['get', 'quantity'], 6], ['<', ['get', 'quantity'], 11]], LegendLevels[3].color,
   ['all', ['>=', ['get', 'quantity'], 11], ['<', ['get', 'quantity'], 17]], LegendLevels[2].color,
   ['all', ['>=', ['get', 'quantity'], 17], ['<', ['get', 'quantity'], 22]], LegendLevels[1].color,
   LegendLevels[0].color
]