import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import ImgCollect from '../assets/img/collect.jpg';
import ImgCreation from '../assets/img/creation.jpg';
import ImgAwareness from '../assets/img/awareness.jpg';
import ImgTag from '../assets/img/tag.jpg';
import { MdClose } from 'react-icons/md';
import { BiPhotoAlbum } from 'react-icons/bi';

function ActivitiesPage(props) {
   const [sliderOpen, toggleSlider] = useState(false);
   const [showSlider, toggleShowSlider] = useState(false);
   const [selectedActivity, selectActivity] = useState(null);
   const [images, setImages] = useState([]);

   const openSlider = (activity) => {
      if(activity) {
         if(activity !== selectedActivity) {
            const images = new Array(10).fill(undefined).map((v,i) => `https://onee-dcem.s3.eu-west-3.amazonaws.com/activities/${activity}/${i+1}`);
            setImages(images)
            selectActivity(activity);
         }
         toggleSlider(true)
         setTimeout(() => { toggleShowSlider(true) }, 100);
      }
   }
   const closeSlider = () => {
      toggleShowSlider(false)
      setTimeout(() => { toggleSlider(false) }, 100);
   }

   return (
      <div className="page activities-page">
         <div className="sections-container">
            <div className="title">Activities</div>
            <div className="activities-container">
               <div className="section">
                  <div className="activity">
                     <div className="activity-img" onClick={() => openSlider('collect')}>
                        <img src={ImgCollect} alt="collect" loading="lazy" />
                        <span className="open"><BiPhotoAlbum/> Explore Images</span>
                     </div>
                     <div className="activity-content">
                        <div className="title">Audit waste /​ Recycling</div>
                        <p className="text">
                           In Dakhla, four beaches were the target of a waste audit carried out by children and supervised by specialists. The objectives of the project were to:
                           <br/>
                           - Characterize the garbage and determine its varieties.
                           <br/>
                           - Educate the kids about beach waste audits, and make them aware of the advantages of categorizing and recycling different waste collected.
                        </p>
                     </div>
                  </div>
               </div>
               <div className="section">
                  <div className="activity">
                     <div className="activity-content">
                        <div className="title">Creative workshops/Science experiments</div>
                        <p className="text">
                           The goal of the creative workshops and scientific experiments is to introduce children to the notion of creating art out of plastic waste through analysis.
                           <br/>
                           Children and young people attended the workshops and were able to learn several scientific concepts, balance, gas production, 
                           as well as see firsthand how pollution harms nature. They were also able to see how simple products on site could be used to 
                           fight against pollution, which is a serious problem.
                           <br/>
                           The children also participated in orientations on the use of plastic to make several models (a car, a storage box, a plane…).
                        </p>
                     </div>
                     <div className="activity-img" onClick={() => openSlider('creation')}>
                        <img src={ImgCreation} alt="collect" loading="lazy" />
                        <span className="open"><BiPhotoAlbum/> Explore Images</span>
                     </div>
                  </div>
               </div>
               <div className="section">
                  <div className="activity">
                     <div className="activity-img" onClick={() => openSlider('tag')}>
                        <img src={ImgTag} alt="collect" loading="lazy" />
                        <span className="open"><BiPhotoAlbum/> Explore Images</span>
                     </div>
                     <div className="activity-content">
                        <div className="title">TAG Workshop T-shirts</div>
                        <p className="text">
                           At each beach, we also had children between the ages of 7 and 12 participate in a workshop to raise awareness of the issue of textile waste.
                           <br/>
                           We demonstrated how to reuse T-shirts either by tagging them with different stencils bearing different messages or pictures to personalize them for the kids' choice, or by turning them into small beach bags.
                           <br/>
                           The concept we wanted to convey to them is that we can always recycle textiles, whether by recycling them or reusing them in another way.
                        </p>
                     </div>
                  </div>
               </div>
               <div className="section">
                  <div className="activity">
                     <div className="activity-content">
                        <div className="title">Mobilization and awareness workshop</div>
                        <p className="text">
                           The purpose of this workshop is to educate children about current and emerging technologies to safeguard our beaches and coastlines. For that, presentations, 
                           awareness videos were shown to groups of 5-7 children, and environmental board games were played by the children
                           <br/>
                           The objectives and themes were diverse and interesting:
                           <br/>
                           - How we can monitor our beaches, seas, and oceans using remote sensing data (satellites).
                           <br/>
                           - What are the major types of pollution that threaten the environment?
                           <br/>
                           - Raise awareness about the dangers that marine plastic waste can pose to marine areas, coastlines, and human health, in order to prevent the proliferation of this material.
                           <br/>
                           - What are the origins, life cycle, and recycling methods of plastic?
                        </p>
                     </div>
                     <div className="activity-img" onClick={() => openSlider('awareness')}>
                        <img src={ImgAwareness} alt="collect" loading="lazy" />
                        <span className="open"><BiPhotoAlbum/> Explore Images</span>
                     </div>
                  </div>
               </div>
               { sliderOpen ? (
               <div className="slider-layer">
                  <div className={`slider-container ${showSlider ? 'show': ''}`}>
                     <button className="close" onClick={closeSlider}><MdClose /></button>
                     <Carousel className="images-slider" showStatus={false} showThumbs={false} useKeyboardArrows={true} emulateTouch={true} swipeable={true} >
                        {images.map((img,i) => (
                        <div key={i}>
                           <img src={img} alt="" />
                        </div>
                        ))}
                     </Carousel>
                  </div>
               </div>
               ): null }
            </div>
         </div>
      </div>
   )
}

export default ActivitiesPage;