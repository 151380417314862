function ContactPage() {

   return (
      <div className="page contact-page">
         <div className="sections-container">
            <div className="section">
               <div className="title">Get in touch with us</div>
               <p className="text">
                  Feel free to contact us if you need some help, or you have other questions.
                  <br/><br/>
                  Please email <a href="mailto:info@eagle-ai.net">info@eagle-ai.net</a>
               </p>
            </div>
         </div>
      </div>
   )
}

export default ContactPage;