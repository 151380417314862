import ReactPlayer from "react-player";
import Deuxm from "../assets/img/2m.png";
import MapPresse from "../assets/img/map.png";
import Laayounetv from "../assets/img/laayounetv.png";

function AboutPage() {

   return (
     <div className="page about-page">
      <div className="sections-container">
         <div className="section">
            <div className="title">ONEE (Environmental actions)</div>
            <p className="text">
               The National Office of Electricity and Drinking Water ( electricity branch) 
               is involved with the Mohammed VI Foundation in programs including 
               "Plages Propres", "Toursime durable" et "Eco-Ecoles".
            </p>
            <p className="text">
               Since its creation in 1999 and the founding of the "Plages Propres" campaigns, 
               ONEE-BE has worked with partners in the seaside locations of Moussafir, Oum Lbouir, Lakhira and Trouk(PK25) in Dakhla
               , Aglou in Tiznit, El Oualidia, Ain Diab in Casablanca, Zenata, Mehdia, and Moulay Bouselham, among other beaches. 
               The Electricity Branch, which sponsors ten beaches during summer, regularly uses ONE's campaign to enhance the environment 
               in the coastal areas. The improvement of the quality of facilities, hygiene, and cleanliness of beaches is a priority. 
               A variety of actors, including hotels, restaurants, and beaches, are educated, in addition to the establishment of basic 
               infrastructure, safety, and health coverage. Safety and health are of particular importance to the cleanliness of beaches. 
               To add to the quality of the beaches, ONEE-BE creates an environment that encourages vacationers to visit.
            </p>
         </div>
         <div className="section">
            <div className="title">Our mission</div>
            <p className="text">
               Dakhla Coasts Environmental Monitoring DCEM is an integrated project that aims to develop a digital tool for environmental 
               monitoring that combines remote sensing data (satellite images) and artificial intelligence (AI) to detect and quantify 
               plastic waste on four target beaches in Dakhla, and this includes Moussafir, Oum Labouir Lakhira and Trouk(PK25).
            </p>
            <p className="text">
               Through activities on the target beaches, we aim to increase awareness of environmental issues, recycling, 
               and technology for the protection of the sea and oceans.
            </p>
         </div>
         <div className="section">
            <div className="title">Videos</div>
            <div className="videos">
               <div className="video-container">
                  <ReactPlayer url='https://youtu.be/xbDnYh34AYE-U' width="100%" />
               </div>
               <div className="video-container">
                  <ReactPlayer url='https://youtu.be/N0mbM6AFyAE' width="100%" />
               </div>
            </div>
         </div>
         <div className="section">
            <div className="title">Media</div>
            <div className="media-container">
               <div className="media">
                  <a href="https://youtu.be/fhrtyl9KiaY" className="link" target="_blank" rel="noreferrer noopener">
                     <img src={Deuxm} alt="2M" />
                  </a>
               </div>
               <div className="media">
                  <a href="https://www.mapdakhla.ma/fr/dakhla-journees-de-sensibilisation-sur-la-protection-du-littoral" className="link" target="_blank" rel="noreferrer noopener">
                     <img src={MapPresse} alt="MAP" />
                  </a>
               </div>
               <div className="media">
                  <a href="https://youtu.be/H5WqRyYNiG4?t=897s" className="link" target="_blank" rel="noreferrer noopener">
                     <img src={Laayounetv} alt="Laayoune TV" />
                  </a>
               </div>
            </div>
         </div>
      </div>
     </div> 
   )
}

export default AboutPage;