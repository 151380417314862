import { useEffect, useRef, useState } from "react";
import { Slider } from "rsuite";

function TimeSlider(props) {
   const [quantities, setQuantities] = useState([]);
   const [sliderVal, setSliderVal] = useState(0);
   const [sliderQuantity, setSliderQuantity] = useState(Date);
   const [newestDate, setNewestDate] = useState(Date);
   const [oldestDate, setOldestDate] = useState(Date);
   const bubbleRef = useRef(null);

   const onChange = (val) => {
      const selectedDate = quantities[val].date;
      props.onChange(selectedDate)
      setSliderVal(val)
      setSliderQuantity(quantities[val])
   }

   // quantities list changed => component mount
   useEffect(() => {
      setQuantities(props.quantities)
      setNewestDate(new Date(props.quantities[props.quantities.length-1]?.date))
      setOldestDate(new Date(props.quantities[0]?.date))
      setSliderVal(props.quantities.length-1);
      setSliderQuantity(props.quantities[props.quantities.length-1])
   }, [props.quantities])

   const bubble = (
      <div ref={bubbleRef} className="bubble-container" >
         <div className="bubble">
            <span>{new Date(sliderQuantity?.date).toLocaleString('en-us',{month:'long'})} </span>
            <span>{new Date(sliderQuantity?.date).toLocaleString('en-us',{year:'numeric'})}</span>
            <span className="num">{sliderQuantity?.quantity} <small> item</small></span>
         </div>
      </div>
   )

   return (
      quantities.length ? (
         quantities.length > 1 ? (
            <div className="time-slider">
               <span className="end">{oldestDate.toLocaleString('en-us',{month:'long', year:'numeric'})}</span>
               <div className="slider">
                  <Slider min={0} max={quantities.length - 1} value={sliderVal}
                     className="slider-input" handleStyle={{ width: 32, height: 22 }}
                     graduated tooltip={false} handleTitle={bubble}
                     onChange={onChange}
                  />
               </div>
               <span className="end">{newestDate.toLocaleString('en-us',{month:'long', year:'numeric'})}</span>
            </div>
         ) : (
            <div className="one-date">
               <span>{new Date(sliderQuantity?.date).toLocaleString('en-us',{month:'long'})} </span>
               <span>{new Date(sliderQuantity?.date).toLocaleString('en-us',{year:'numeric'})}</span>
            </div>
         )
      ) : null
   )
}

export default TimeSlider;