import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import SideNav from './components/SideNav';
import AboutPage from './pages/AboutPage';
import ActivitiesPage from './pages/ActivitiesPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import MapPage from './pages/MapPage';
import ONE from './assets/img/one.png';

export default function App() {
  const [landed, setLanded] = useState(false);
  const [screenWidth, setScreenWidth] = useState(undefined)
  const windowSize = useWindowSize();

  // Reload screen if the width changed. 
  // We need to concider only the sizes that are used by css responsive styling which are: 769px, 1025
  useEffect(() => {
    let newWidth;
    if(windowSize.width <= 769) newWidth = 769
    else if(windowSize.width > 769 && windowSize.width <= 1025) newWidth = 1025
    else if(windowSize.width > 1025) newWidth = 1026
    
    if(screenWidth && screenWidth !== newWidth) window.location.reload()
    
    setScreenWidth(newWidth)
  }, [windowSize.width,screenWidth])

  return (
    <>
      <SideNav />
      <div className="App">
        <header>
          <a href="http://www.one.org.ma" className="logo" target="_blank" rel="noopener noreferrer">
            <img src={ONE} alt="one" />
          </a>
        </header>
        <Routes>
          <Route path="/" element={<HomePage landed={landed} land={(l)=>setLanded(l)} />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="/activities" element={<ActivitiesPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <footer>
          <span className="text">© {new Date().getFullYear()} <a href="http://www.one.org.ma" target="_blank" rel="noopener noreferrer">ONEE-BE</a>. Powered by <a href="https://eagle-ai.net" target="_blank" rel="noopener noreferrer">EAGLE AI</a></span>
            <img src={ONE} alt="one" className="logo" />
        </footer>
      </div>
    </>
  );
}

// window size hook to keep updated with screen changes
// for now we only needs to work with width because of css responsive styling (media queries)
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}