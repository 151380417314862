import { BiArrowBack, BiDownload } from 'react-icons/bi';
import { useEffect, useRef, useState } from 'react';
import { Carousel } from 'rsuite'
import { TbReportAnalytics } from 'react-icons/tb';
import { MdClose } from 'react-icons/md';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import mapboxgl from 'mapbox-gl';
import SitesMap from '../utils/dakhla-sites.geojson';

ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend);
 
 export const chartOptions = (title) => {
   return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
         legend: {
            position: 'top'
         },
         title: {
            display: true,
            text: `Plastic Detected from ${title}`
         },
      },
      parsing: {
         xAxisKey: 'date',
         yAxisKey: 'quantity'
      }
   }
 };

export const chartData = (data) => {
   return {
      datasets: [
         {
            fill: true,
            label: 'Plastic Items',
            data: data,
            borderColor: 'rgb(123 128 255)',
            backgroundColor: 'rgba(123, 128, 255, 0.5)',
         },
      ]
   }
};

function SiteReport(props) {
   const [images, setImages] = useState([]);
   const [properties, setProperties] = useState([]);
   const [reportShown, showReport] = useState(false);
   const [quantities, setQuantities] = useState([]);
   const mapContainer = useRef(null);
   const map = useRef(null);

   useEffect(() => {
      // site polygon on the map
      if (!map.current) { // initialize map only once
         map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-v9',
            bounds: JSON.parse(props.site?.properties.bounds),
            interactive: false
         })
         map.current.on('load', () => {
            map.current.addSource('sites', {
               'type': 'geojson',
               'data': SitesMap
            });
            map.current.addLayer({
               'id': 'sites-layer',
               'type': 'fill',
               'source': 'sites',
               'paint': {
                  'fill-color': '#7b80ff',
                  'fill-opacity': 1
               }
            });
            map.current.addLayer({
               'id': 'sites-borders',
               'type': 'line',
               'source': 'sites',
               'layout': {},
               'paint': {
                  'line-color': '#FFCD00',
                  'line-width': 1.5
               }
            });
            // map.current.setFilter('sites-layer', ['==', 'id', props.site.id])
         })
      }
   }, [props.site])
   
   useEffect(() => {
      setImages([
         `${props.site.properties?.s3}1`,
         `${props.site.properties?.s3}2`,
         `${props.site.properties?.s3}3`
      ])
      setProperties(JSON.parse(props.site.properties?.displayed))
   }, [props.site])

   // Format of dates shown in chart
   useEffect(() => {
      const quantitiesFormed = props.quantities.map((qD) => {
         return { date: new Date(qD.date).toLocaleString('en-us',{month:'long', year:'numeric'}), quantity: qD.quantity }
      })
      setQuantities(quantitiesFormed)
   }, [props.quantities])

   const toggleReport = () => {
      showReport(!reportShown)
   }

   const downloadReport = () => {
      window.open(`${props.site.properties?.s3}${props.site.properties?.name} report`)
   }

   return(
      <div className={`report-container ${(reportShown) ? "opened":"closed"}`} >
         <div className="report">
            <div className="header">
               <button className='reset-btn' onClick={props.resetSitesView}><BiArrowBack /></button>
               <div className='title'>{props.site.properties?.name}</div>
               <button className='report-btn' onClick={toggleReport}>{(reportShown) ? <MdClose/>:<TbReportAnalytics/>} Dashboard</button>
            </div>
            <div className="body">
               <div className="left">
                  <Carousel autoplay className="images-slider">
                     {images.map((img,i) => (
                     <div key={i}>
                        <div className="image" style={{backgroundImage:'url('+img+')'}}></div>
                     </div>
                     ))}
                  </Carousel>
                  <div className="coordinates">{props.site.properties?.dms}</div>
                  <div className="properties">
                     {properties.map((prop,i) => (
                        <div key={i} className="property">
                           <span className="label">{prop.label}</span> <span>{prop.value}</span>
                        </div>
                     ))}
                  </div>
                  <button className="down-btn" onClick={downloadReport}><BiDownload /> Download Report</button>
               </div>
               <div className="right">
                  <div ref={mapContainer} className="map-snapshot" />
                  <div className="chart-container">
                     <Line className="chart" options={chartOptions(props.site.properties?.name)} data={chartData(quantities)} />
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default SiteReport;